/////////////////////////////////////////////////////
// Smooth Scroll
/////////////////////////////////////////////////////

 //////////////
// DEPRECATED
/*
jQuery(document).ready( function($) {

    function scrollToSection(event) {

        event.preventDefault();

		// Scroll to Top
        if ( $(this).attr('href') === '#top' ) {
			
			$("html, body").animate({ scrollTop: 0 }, "slow");

		// Scroll to Section ID
		} else {
			var $section = $($(this).attr('href'));
        
			$('html, body').animate({
				scrollTop: $section.offset().top
			}, 520);

		}
	}

	$('[href*="#"]').on( 'click', scrollToSection );
	
}(jQuery));
*/

// document.querySelectorAll('[href*="#"]').forEach( function( elem ) {
// 	elem.addEventListener('click', function() {
// 		event.preventDefault();

// 		var section = document.getElementByID( this.attr );

// 		document.section.scrollIntoView({ behavior: 'smooth' });
// 	}
// });

// Link: http://iamdustan.com/smoothscroll/