<template>
	<div class="masonry">

		<button class="masonry__brick"
			@click.prevent="openSlide(index)"
			:key="'image--' + index"
			v-for="(image, index) in images">
			<img :src="image" :alt="'image-' + index">
		</button>

		<modal 
			class="modal--lightbox"
			ref="modal"
			triggerClass="none">
			<span slot="modal-trigger" class="none"></span>
			<rotator-lightbox 
				slot="modal-content" 
				ref="rotatorLightbox"
				:list="images" 
				:open-at-index="openAtIndex" 
			/>
		</modal>

	</div>
</template>

<script>
import Modal from './Modal';
import RotatorLightbox from './RotatorLightbox';

export default {

	components: {
		Modal,
		RotatorLightbox,
	},

    props: {
        list: {
            default: () => [],
        },
    },

    data() {
        return {
            images: [],
			openAtIndex: 0,
        }
    },

    methods: {
        parseImages() {
            const parsedList = JSON.parse(this.$props.list);
            parsedList.forEach( image => {
                this.images.push( image.image )
            });
        },
	   
		openSlide( index ) {
			this.openAtIndex = index;
			this.$refs.rotatorLightbox.updateSlide(index);
			this.$refs.rotatorLightbox.updateHeight();
			this.$refs.modal.open();
		},
    },

    created() {
        this.parseImages();
    },

    mounted() {

    },
}
</script>