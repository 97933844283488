var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal__wrapper", class: _vm.modalClass }, [
    _c(
      "button",
      {
        class: _vm.triggerClass,
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.open($event)
          }
        }
      },
      [_vm._t("modal-trigger", [_vm._v("Open Modal")])],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOpen,
            expression: "isOpen"
          }
        ],
        staticClass: "modal",
        class: _vm.currentClass
      },
      [
        _c("div", {
          staticClass: "modal__overlay",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.close($event)
            }
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal__container" },
          [
            _c("button", {
              staticClass: "modal__close-button",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.close($event)
                }
              }
            }),
            _vm._v(" "),
            _vm._t("modal-content", [_vm._v("Modal Content")])
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }