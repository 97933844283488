/////////////////////////////////////////////////////
// Appear when in viewport
/////////////////////////////////////////////////////
/*
  Put the `data-appear` attribute on the element to be animated
  add a value for the animation type

  e.g. `data-appear="fade-up"` will cause the element to
        subtly move upward into position while fading in.

  You can use `data-appear-delay="number"` to set a delay on the
  animation. This is useful if you want things to appear one after the other

  e.g. `data-appear-delay="150"` would delay the animation by 150 milliseconds.
*/
/////////////////////////////////////////////////////

// jQuery(document).ready(function($) {

// 	function appearElems() {
// 		// Current Scroll Position
// 		var curPos = $(window).scrollTop();
// 		// Bottom of Window
// 		var windowBottom = $(window).height();
// 		// Within Viewport
// 		var bottomPos = curPos + windowBottom;
// 		// Element to affect
// 		var $appearElem = $('[data-appear]');
// 		// Animation Delays
// 		var $delay = $('[data-appear-delay]');

// 		if( $delay.length > 0 ) {
// 			$delay.each(function() {
// 				var $delayElem = $(this);
		
// 				$delayElem.css({
// 					'transition-delay': $delayElem.attr('data-appear-delay') + 'ms'
// 				});
// 			});
// 		}
	
// 		// Make the element appear when it enters the viewport or is already above
// 		// the viewport
// 		function makeAppear() {
// 			var elemPos = $(this).offset().top;
// 			var firePos = curPos - 60;

// 			if(elemPos < bottomPos && (elemPos >= firePos || elemPos < firePos)) {
// 				$(this).addClass('has-appeared');
// 			}
// 		}
  
// 		$appearElem.each(makeAppear);
// 		$(window).on('scroll', function() {
// 			// Current Scroll Position
// 			curPos = $(window).scrollTop();
// 			// Bottom of Window
// 			windowBottom = $(window).height();
// 			// Within Viewport
// 			bottomPos = curPos + windowBottom;
// 			$appearElem.each(makeAppear);
// 	  	});
// 	}
// 	appearElems();
// });