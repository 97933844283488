<template>
    <div 
        class="rotator rotator--lightbox" 
        :style="'height: ' + rotatorHeight + 'px;'">
        <button 
            class="rotator__button rotator__button--left" 
            @click.prevent="showPrev"  
            v-if="images.length > 1">
            <IconArrowLeft/>
        </button>
        <div class="rotator__slide"
            :class="isActiveClass(index)"
            v-for="(image, index) in images" 
            :key="'image--' + index">
            <img class="rotator__image" :src="image" />
        </div>
        <button 
            class="rotator__button rotator__button--right" 
            @click.prevent="showNext"
            v-if="images.length > 1">
            <IconArrowRight/>
        </button>
    </div>
</template>

<script>
import RotatorBase from './RotatorBase';
import IconArrowLeft from './Icons/IconArrowLeft';
import IconArrowRight from './Icons/IconArrowRight';

export default {
    extends: RotatorBase, 

    components: {
        IconArrowLeft,
        IconArrowRight,
    },

    props: {
        list: {
            default: () => [],
        },
        openAtIndex: {
            default: 0,
        }
    },

    data() {
        return {
            images: [],
            activeSlide: 0,
        }
    },

    methods: {
        parseImages() {
            this.images = this.list;
        },

        showPrev() {
            this.goToPrevSlide(this.images);
        },

        showNext() {
            this.goToNextSlide(this.images);
        },

        updateHeight() {
            this.checkHeight()
        },

        updateSlide(index) {
            this.activeSlide = index;
        },
    },

    created() {
        this.parseImages();
    },

    mounted() {

    },
}
</script>