var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-arrow-right",
      staticStyle: { "enable-background": "new 0 0 57 101.7" },
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        x: "0px",
        y: "0px",
        viewBox: "0 0 57 101.7",
        "xml:space": "preserve"
      }
    },
    [
      _c("polygon", {
        staticStyle: { fill: "currentColor" },
        attrs: { points: "6.1,0 0,6.1 44.7,50.9 0,95.6 6.1,101.7 57,50.9 " }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }