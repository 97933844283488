<template>
    <div class="video-playlist">
        <div class="current-video-container">
            <div class="playlist-item">
                <div v-for="(video, index) in playlist" class="current-video" :key="'video-' + index">
                    <transition name="fade">
                        <video-player
                            v-if="currentVideo == index"
                            :id="'playlist-video-' + index"
                            :video="video.video_url" 
                            :title="video.video_title"
                            :index="index"
                            :showTitle="false"
                            :autoPlay="shouldPlay"
                        />
                    </transition>
                </div>
            </div>
        </div>
        
        <div class="video-list">
            <div v-for="(video, index) in playlist" class="playlist-item" :class="currentVideo === index ? 'is-selected' : ''" @click="videoClicked(index)" :key="'video-li-' + index">
                <div class="video-thumb-wrapper">
                    <div class="video-thumb" :style="getVideoThumbStyle(video.video_url)">
                        <icon-play />
                    </div>
                </div>
                <h3>{{ video.video_title }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
import YouTube from "./YouTube";
import VideoPlayer from "./VideoPlayer";

export default {
    extends: YouTube,
    
    props: [
        'videos',
    ],

    components: {
        VideoPlayer,
    },

    data() {
        return {
            playlist: [],
            currentVideo: null,
            shouldPlay: false,
        }
    },

    methods: {
        parsePlaylist() {
            this.playlist = JSON.parse(this.$props.videos);
        },
        setCurrentVideo(index) {
            this.currentVideo = index;
        },
        updateCurrentVideo(index) {           
            this.setCurrentVideo(index);
        },
       
        videoClicked(index) {
            this.shouldPlay = true;
            this.updateCurrentVideo(index);
        },
    },

    mounted() {
        this.parsePlaylist();
        this.setCurrentVideo(0);
    }
}
</script>