/////////////////////////////////////////////////////
// Drawer Nav
/////////////////////////////////////////////////////

document.body.classList.add( 'nav-closed' );

function drawer_nav() {

	const bodyElem     = document.body;
	const buttonElem   = document.querySelector('[trigger="navigation"]');
	const openClass    = 'nav-open';
	const openingClass = 'nav-opening';
	const closingClass = 'nav-closing';
	const closedClass  = 'nav-closed';

	buttonElem.addEventListener('click', function() {
		event.preventDefault();
		
		if ( bodyElem.classList.contains( closedClass ) ) {

			// nav no longer closed, nav opening
			bodyElem.classList.replace( closedClass, openingClass );
			buttonElem.disabled = true;

			setTimeout( function() {

				// nav no longer opening, nav closing
				bodyElem.classList.replace( openingClass, openClass );
				buttonElem.disabled = false;

			}, 640);
		}

		if ( bodyElem.classList.contains( openClass ) ) {

			// nav no longer opened, nav closing
			bodyElem.classList.replace( openClass, closingClass );
			buttonElem.disabled = true;

			setTimeout( function() {

				// nav no longer closing, nav closed
				bodyElem.classList.replace( closingClass, closedClass );
				buttonElem.disabled = false;

			}, 640);
		}
		
	});
};

document.addEventListener('DOMContentLoaded', drawer_nav, false);