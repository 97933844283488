<script>
import IconPlay from "./Icons/IconPlay";

export default {
    props: {
        'video': String
    },
    
    components: {
        IconPlay,
    },

    data(){
        return { 
            youtubeCallbackName: 'onYouTubeIframeAPIReady',
            youtubeExistsFlag:   '$isYoutubeFrameAPIReady',
            videoDuration: '',
        }
    },

    methods: {
        getVideoId(url) {
            // YouTube's video IDs are always 11 characters, so we can isolate
            // it by getting a substring of the URL and grabbing the last 11 characters
            return url.substr(url.length - 11);
        },

        getVideoThumbStyle(url) {
            return 'background-image:url(http://img.youtube.com/vi/' + this.getVideoId(url) + '/0.jpg);';
        },

        whenYoutubeAPIReady(){
            const existsFlag = this.youtubeExistsFlag;
            return new Promise(function(resolve, reject) {
                let elapsed = 0;
                let intervalHandle;
                let checker = function() {
                    elapsed += 48;
                    if(!!window[existsFlag]) {
                        clearTimeout(intervalHandle);
                        resolve();
                    }
                    else{
                        if(elapsed <= 15000){
                            intervalHandle = setTimeout(checker, 48);
                        }
                        else{
                        reject("timeout");
                        }
                    }
                }; 
                
                setTimeout(checker,48);
            });
        },
        hasYoutubeFrameAPI(){
            if(!this.hasYTFrame) {
                this.hasYTFrame = !!(document.getElementsByClassName('.yt-frame-api').length);
            }
            return this.hasYTFrame;
        },
            
        injectYoutubeFrameAPI() {
            const youtubeExistsFlag   = this.youtubeExistsFlag;
            const youtubeCallbackName = this.youtubeCallbackName;
            
            window[this.youtubeCallbackName] = window[this.youtubeCallbackName] || function() {
                window[youtubeExistsFlag] = true;
                window[youtubeCallbackName] = null;
                delete window[youtubeCallbackName];
            }
            
            // Add YouTube iframe API
            var tag       = document.createElement('script');
            var first     = document.getElementsByTagName('script')[0];
            tag.src       = "https://www.youtube.com/iframe_api";
            tag.className = "yt-frame-api";
            first.parentNode.insertBefore(tag, first);
        },

        youtubeInit(url) {
            if(!this.hasYoutubeFrameAPI()){
                this.injectYoutubeFrameAPI();
            }
            this.whenYoutubeAPIReady().then(
                () => {
                    const player = this.$el.querySelector('.video-placeholder');
                    player.id = 'player-' 
                    + Math.floor(Math.random()*1024) 
                    + Date.now() 
                    + Math.floor(Math.random()*1024);
                    
                    this.YTPLayer = new YT.Player(player.id, {
                        height: '360',
                        width: '640',
                        videoId: this.getVideoId(url),
                    });
                },
                error => console.error(error)
            );
        },

        playVideo() {
            this.YTPLayer.playVideo();
        },
        
        stopVideo() {
            this.YTPLayer.stopVideo();
        },
    },

    mounted() {
        
    },
}
</script>