import { render, staticRenderFns } from "./IconArrowLeft.vue?vue&type=template&id=3954e685&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/aarondeckler/Sites/stp/site/themes/boilerplate/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3954e685')) {
      api.createRecord('3954e685', component.options)
    } else {
      api.reload('3954e685', component.options)
    }
    module.hot.accept("./IconArrowLeft.vue?vue&type=template&id=3954e685&", function () {
      api.rerender('3954e685', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/src/vue/Components/Icons/IconArrowLeft.vue"
export default component.exports