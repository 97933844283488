var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "masonry" },
    [
      _vm._l(_vm.images, function(image, index) {
        return _c(
          "button",
          {
            key: "image--" + index,
            staticClass: "masonry__brick",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.openSlide(index)
              }
            }
          },
          [_c("img", { attrs: { src: image, alt: "image-" + index } })]
        )
      }),
      _vm._v(" "),
      _c(
        "modal",
        {
          ref: "modal",
          staticClass: "modal--lightbox",
          attrs: { triggerClass: "none" }
        },
        [
          _c("span", {
            staticClass: "none",
            attrs: { slot: "modal-trigger" },
            slot: "modal-trigger"
          }),
          _vm._v(" "),
          _c("rotator-lightbox", {
            ref: "rotatorLightbox",
            attrs: {
              slot: "modal-content",
              list: _vm.images,
              "open-at-index": _vm.openAtIndex
            },
            slot: "modal-content"
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }