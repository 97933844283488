<template>
	<div class="modal__wrapper" :class="modalClass">
		<button :class="triggerClass" @click.prevent="open">
			<slot name="modal-trigger">Open Modal</slot>
		</button>
		<div class="modal" :class="currentClass" v-show="isOpen">
			<div class="modal__overlay" @click.prevent="close"></div>
			<div class="modal__container">
				<button class="modal__close-button" @click.prevent="close"></button>
				<slot name="modal-content">Modal Content</slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {

	props: {
		triggerClass: { 
			default: 'link'
		},
		modalClass: { 
			default: ''
		},
	},

	data() {
		return {
			isOpen: false,
			currentClass: 'modal--closed',
		}
	},
	
	methods: {

		open() {
			this.modalOpening();
			setTimeout( () => {
				this.modalOpen();
			}, 640);
		},

		close(){
			this.modalClosing();
			setTimeout( () => {
				this.modalClosed();
			}, 640);
		},

		modalOpening() {
			this.isOpen = true;
			document.body.classList.add( 'scroll-lock', 'modal--active' );
			this.currentClass = 'modal--opening';
		},
		modalOpen() {
			this.currentClass = 'modal--open';
		},
		modalClosing() {
			this.currentClass = 'modal--closing';
		},
		modalClosed() {
			document.body.classList.remove( 'scroll-lock', 'modal--active' );
			this.currentClass = 'modal--closed';
			this.isOpen = false;
		},
		
	}
}
</script>