<script>
import IconArrowLeft from './IconArrowLeft';
import IconArrowRight from './IconArrowRight';
import IconPlay from './IconPlay';

export default {
	components: {
		IconArrowLeft,
		IconArrowRight,
		IconPlay,
	}
}
</script>