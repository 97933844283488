<template>
		
</template>

<script>
import { TweenMax } from 'gsap';

export default {
	data() {
		return {
			rotatorHeight: 0,
			activeSlide: 0,
		}
	},

	methods: {
		setSlideHeights() {
			const slides = document.querySelectorAll('.rotator__slide');
			const slideHeights = [];
		
			for(let i = 0; i < slides.length; i++) {
				slideHeights.push(slides[i].clientHeight);
			}
			this.rotatorHeight = Math.max(...slideHeights);
		},

		isActiveClass(index) {
			return this.activeSlide === index ? 'is-active' : '';
		},

		goToNextSlide(arr) {
			if(this.activeSlide < arr.length - 1) {
				this.activeSlide++;
			} else {
				this.activeSlide = 0;
			}
		},

		goToPrevSlide(arr) {
			if(this.activeSlide > 0) {
				this.activeSlide--;
			} else {
				this.activeSlide = arr.length - 1;
			}
		},

		checkHeight() {
			if ( this.rotatorHeight === 0 ) {
				setTimeout(() => {
					this.setSlideHeights();
					this.checkHeight()
				}, 100);	
			}
		}
	},

	mounted() {
		// if the site is being laggy this should double check it
		this.checkHeight()

		window.addEventListener('resize', () => {
			this.setSlideHeights()
		});
	},
}
</script>