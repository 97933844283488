<template>
    <div class="rotator rotator--image">
        <button 
            class="rotator__button rotator__button--left" 
            @click.prevent="showPrev" 
            v-if="images.length > 1">
            <IconArrowLeft/>
        </button>
        <div class="rotator__slide" 
            :class="isActiveClass(index)"
            v-for="(image, index) in images" 
            :key="'image--' + index"
            :style="'background-image: url(' + image + ');'">
        </div>
        <button 
            class="rotator__button rotator__button--right" 
            @click.prevent="showNext" 
            v-if="images.length > 1">
            <IconArrowRight/>
        </button>
    </div>
</template>

<script>
import RotatorBase from './RotatorBase';
import IconArrowLeft from './Icons/IconArrowLeft';
import IconArrowRight from './Icons/IconArrowRight';

export default {
    extends: RotatorBase,

    components: {
        IconArrowLeft,
        IconArrowRight,
    },

    props: ['list'],

    data() {
        return {
            images: [],
            activeSlide: 0,
        }
    },

    methods: {
        parseImages() {
            const parsedList = JSON.parse(this.$props.list);
            parsedList.forEach( image => {
                this.images.push( image.image )
            });
        },

        showPrev() {
            this.goToPrevSlide(this.images);
        },

        showNext() {
            this.goToNextSlide(this.images);
        },
       
    },

    created() {
        this.parseImages();
    },

    mounted() {

    },
}
</script>