var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "rotator rotator--lightbox",
      style: "height: " + _vm.rotatorHeight + "px;"
    },
    [
      _vm.images.length > 1
        ? _c(
            "button",
            {
              staticClass: "rotator__button rotator__button--left",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.showPrev($event)
                }
              }
            },
            [_c("IconArrowLeft")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.images, function(image, index) {
        return _c(
          "div",
          {
            key: "image--" + index,
            staticClass: "rotator__slide",
            class: _vm.isActiveClass(index)
          },
          [_c("img", { staticClass: "rotator__image", attrs: { src: image } })]
        )
      }),
      _vm._v(" "),
      _vm.images.length > 1
        ? _c(
            "button",
            {
              staticClass: "rotator__button rotator__button--right",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.showNext($event)
                }
              }
            },
            [_c("IconArrowRight")],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }