
import Vue             from 'vue';
import Vue2TouchEvents from 'vue2-touch-events'
import Icons           from './Components/Icons/Icons';
import Modal           from './Components/Modal';
import VideoPlaylist   from './Components/VideoPlaylist';
import RotatorBase     from './Components/RotatorBase';
import RotatorImages   from './Components/RotatorImages';
import RotatorLightbox from './Components/RotatorLightbox';
import Masonry         from './Components/Masonry';

new Vue({
	el: '#app',
	components: {
		Icons,
		Modal,
		VideoPlaylist,
		RotatorBase,
		RotatorImages,
		RotatorLightbox,
		Masonry,
	}
});

Vue.use(Vue2TouchEvents)