/////////////////////////////////////////////////////
// Modal
/////////////////////////////////////////////////////

function modal() {

	const bodyElem = document.body;
	// wrapper for the modal
	const modalElems = document.querySelectorAll( '.modal-wrapper' );
	// elements for opening the modal
	const modalOpenable = document.querySelectorAll( '[href*="^"]' );
	// elements for closing the modal
	const modalCloseable = document.querySelectorAll( '[trigger="modal-close"]' );
	// active state classes
	const openClass    = 'modal-open';
	const openingClass = 'modal-opening';
	const closingClass = 'modal-closing';
	const closedClass  = 'modal-closed';

	bodyElem.classList.add('modal-closed');

	let transitionElms = [
		bodyElem
	]

	modalElems.forEach( function( elem ) {
		transitionElms.push( elem )
	});
	
	function toggleActive( activeClass, newClass ) {

		transitionElms.forEach( function( elem ) {
			elem.classList.replace( activeClass, newClass );
		});

	};

	// for each button that closes the modal on click, close the modal
	modalCloseable.forEach( function( elem ) {
		elem.addEventListener('click', function() {
			event.preventDefault();

			// modal no longer opened, modal closing
			toggleActive( openClass, closingClass );

			setTimeout( function() {

				// modal done closing, modal now closed
				toggleActive( closingClass, closedClass );

			}, 640);
		});
	});
	

	// for each modal button activate its corresponding modal on click
	modalOpenable.forEach( function( elem ) {
		elem.addEventListener("click", function() {
			event.preventDefault();

			let current = this.getAttribute('href').replace('^','');

			// modal no longer closed, modal opening
			document.getElementById( current ).classList.replace( closedClass, openingClass );
			bodyElem.classList.replace( closedClass, openingClass );

			setTimeout( function() {

				// modal done opening, modal now opened
				document.getElementById( current ).classList.replace( openingClass, openClass );
				bodyElem.classList.replace( openingClass, openClass );

			}, 640);
		});
	});
};

document.addEventListener('DOMContentLoaded', modal, false);