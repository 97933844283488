/////////////////////////////////////////////////////
// Accordion
/////////////////////////////////////////////////////

function accordion() {
	const accordion = '.accordion';
	const collapsible = '.collapsible';
	const button = '[trigger="collapsible"]';
	const active = 'collapsible-open';
	
    document.querySelectorAll( accordion ).forEach( function( wrap, index ) {

		// Set an index for each accordion
		wrap.classList.add( `accordion_${index}` )

		// make sure we set our current button and collapsible to the direct children of the indexed accordion
		let currentButton = null;
		let currentCollaps = null;

		wrap.querySelectorAll( button ).forEach( function( inner ) {
			if ( wrap === inner.closest( accordion ) ) {
				currentButton = inner;
			}
		});

		wrap.querySelectorAll( collapsible ).forEach( function( inner ) {
			if ( wrap === inner.closest( accordion ) ) {
				currentCollaps = inner;
			}
		});

		// get the height of each collapsible before collapsing it
		let collapsibleHeight = 0;
		let duration = 0;
		let heightChecked = false;
		
		if ( !heightChecked ) {
			
			collapsibleHeight = currentCollaps.offsetHeight;
			duration = collapsibleHeight + 400
			heightChecked = true;

			setTimeout(() => {
				
				currentCollaps.setAttribute("style", `
					max-height: 0; 
					transition-duration: ${duration}ms;
				`);
				
			}, 0);
		};

		// make a button to toggle the open and closed states of the accordion
		currentButton.addEventListener( 'click', function()  {
			event.preventDefault();

			const outside = this.closest( accordion );

			if ( outside.classList.contains( active ) ) {
				
				outside.classList.toggle( active );
				outside.querySelector( collapsible ).setAttribute( "style", `
					max-height: 0;
					transition-duration: ${duration}ms;
				`);

			} else {
				
				outside.classList.toggle( active );
				outside.querySelector( collapsible ).setAttribute("style", `
					max-height: ${collapsibleHeight}px; 
					transition-duration: ${duration}ms;
				`);

			}

		});
	});
};

document.addEventListener('DOMContentLoaded', accordion, false);