var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-arrow-left",
      staticStyle: { "enable-background": "new 0 0 57 101.7" },
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        x: "0px",
        y: "0px",
        viewBox: "0 0 57 101.7",
        "xml:space": "preserve"
      }
    },
    [
      _c("polygon", {
        staticStyle: { fill: "currentColor" },
        attrs: { points: "50.9,0 57,6.1 12.3,50.9 57,95.6 50.9,101.7 0,50.9 " }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }