var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-playlist" }, [
    _c("div", { staticClass: "current-video-container" }, [
      _c(
        "div",
        { staticClass: "playlist-item" },
        _vm._l(_vm.playlist, function(video, index) {
          return _c(
            "div",
            { key: "video-" + index, staticClass: "current-video" },
            [
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.currentVideo == index
                    ? _c("video-player", {
                        attrs: {
                          id: "playlist-video-" + index,
                          video: video.video_url,
                          title: video.video_title,
                          index: index,
                          showTitle: false,
                          autoPlay: _vm.shouldPlay
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "video-list" },
      _vm._l(_vm.playlist, function(video, index) {
        return _c(
          "div",
          {
            key: "video-li-" + index,
            staticClass: "playlist-item",
            class: _vm.currentVideo === index ? "is-selected" : "",
            on: {
              click: function($event) {
                return _vm.videoClicked(index)
              }
            }
          },
          [
            _c("div", { staticClass: "video-thumb-wrapper" }, [
              _c(
                "div",
                {
                  staticClass: "video-thumb",
                  style: _vm.getVideoThumbStyle(video.video_url)
                },
                [_c("icon-play")],
                1
              )
            ]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(video.video_title))])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }