<template>
    <div class="video-container">
        <div class="video-player-wrapper">
            <div class="video-block">
                <div class="video-placeholder"></div>
                <transition name="fade" mode="out-in">
                    <div class="video-thumb" 
                        v-if="thumbIsVisible" 
                        @click="videoClicked"
                        :style="getVideoThumbStyle(video)">
                        <icon-play />
                    </div>
                </transition>
            </div>
        </div>
        <h3 v-if="showTitle" @click="videoClicked">
            {{ title }}
        </h3>
    </div>
</template>

<script>
import YouTube from "./YouTube";

export default {
    extends: YouTube,

    props: {
        'video': String,
        'title': String,
        'showTitle': {
            type: Boolean,
            default: true,
        },
        'index': {
            type: Number,
            default: 0,
        },
        'autoPlay': {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return { 
            thumbIsVisible: true,
        }
    },

    methods: {
        videoClicked() {
            this.playVideo();
            this.thumbIsVisible = false;
        },
    },

    mounted() {
        this.youtubeInit(this.$props.video);
        
        if(this.$props.autoPlay) {
            setTimeout(() => { 
                this.videoClicked();
            }, 1000);
        }
    }
}
</script>