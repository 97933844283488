var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-container" }, [
    _c("div", { staticClass: "video-player-wrapper" }, [
      _c(
        "div",
        { staticClass: "video-block" },
        [
          _c("div", { staticClass: "video-placeholder" }),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.thumbIsVisible
              ? _c(
                  "div",
                  {
                    staticClass: "video-thumb",
                    style: _vm.getVideoThumbStyle(_vm.video),
                    on: { click: _vm.videoClicked }
                  },
                  [_c("icon-play")],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm.showTitle
      ? _c("h3", { on: { click: _vm.videoClicked } }, [
          _vm._v("\n        " + _vm._s(_vm.title) + "\n    ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }